@use "sass:list";

@use "../../partials/mixins";

@use "@jeffcarbine/premmio/public/styles/mixin";

@use "../../../components/episodeBox/episodeBox";
@use "../../partials/live";
@use "../../partials/productSummary";
@use "@jeffcarbine/premmio/public/components/product/product";

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes characterFromBottom {
  from {
    transform: translateY(1rem);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

#about {
  @include mixins.blackBackground;
  text-align: center;

  .headshots {
    display: flex;
    max-width: 50rem;
    margin: 1rem auto;

    figure {
      flex: 1 1 25%;

      &:nth-of-type(1) {
        transform: rotate(4deg);
      }

      &:nth-of-type(2) {
        transform: rotate(-3deg);
      }

      &:nth-of-type(3) {
        transform: rotate(4deg);
      }

      &:nth-of-type(4) {
        transform: rotate(-2deg);
      }

      img {
        width: 100%;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border: 0.25rem solid var(--light-900);
      }
    }
  }
}

#cast {
  --campaignArrowColor: var(--accent-500);
  padding-bottom: 4rem;
  overflow: hidden;

  #details {
    margin-bottom: 6rem;
  }

  h1 {
    text-align: center;
    padding-top: 2rem;
    position: relative;
    z-index: 10;
    font-size: var(--fs8);
    letter-spacing: 1px;
  }

  #campaignControls {
    text-align: center;
    position: relative;
    z-index: 3;

    .groupNameSlider {
      margin-top: 1rem;
      height: 8rem;
      max-width: 70rem;
      width: 100%;
      display: block;
      position: relative;

      .campaignDetails {
        position: absolute;
        z-index: 21;
        cursor: pointer;
        margin-top: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.5s ease;
        width: 100%;

        h2 {
          letter-spacing: 1px;
          margin-bottom: 0;
          font-size: var(--fs2);
          font-family: var(--AccentFont);
          font-weight: 900;
          text-transform: uppercase;

          span {
            font-size: 0.4em;
            margin-top: 0.5rem;
            color: var(--accent-500);
          }
        }
      }
    }
  }

  #campaigns {
    position: relative;
    transition: opacity 0.5s ease;

    .groups {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 2rem);
      max-width: 35rem;
      height: 100%;

      .campaignArrows {
        width: 110%;
        left: -5%;
      }

      .group {
        --headshot-width: 5rem;

        display: none;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease;

        .dungeonMaster,
        .character {
          position: absolute;

          a.characterName {
            position: absolute;
            font-size: var(--fs12);
            color: var(--dark-900);
            font-family: var(--AccentFont);
            font-weight: 500;
            text-transform: uppercase;
            text-decoration: none;
            width: auto;

            img.headshot {
              width: var(--headshot-width);
              height: auto;
              aspect-ratio: 1 / 1;
              object-fit: cover;
              border-radius: 50%;
              display: block;
              margin: -0.5rem auto;
              box-shadow: var(--box-shadow);
            }

            span.shortName {
              display: block;
              text-align: center;
              position: relative;
              z-index: 1;
              padding: 1rem 0.5rem 0.5rem;
              text-shadow: var(--text-shadow);

              span {
                &.player {
                  margin: 0 auto;
                  display: flex;
                  justify-content: center;
                  color: var(--light-900);
                  font-size: var(--fs11);
                  font-weight: 900;
                }

                &.pc {
                  margin-top: 0.25rem;
                  display: block;
                  font-size: var(--fs9);

                  font-family: var(--AccentFont);
                  font-weight: 900;
                  color: var(--accent-500);
                }
              }
            }
          }
        }

        .dungeonMaster {
          top: -5rem;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
          width: 35%;
          height: 35%;

          img.illustration {
            width: 100%;
            height: 100%;
            object-fit: contain;
            opacity: 0;
            transform: translateY(1rem);
            animation: characterFromBottom 0.5s forwards;
          }

          a.characterName {
            top: calc(100% - 2rem);
            left: 50%;
            transform: translateX(-50%);

            img.headshot {
              top: 0;
            }
          }
        }

        .character {
          bottom: 35%;
          z-index: 2;

          img.illustration {
            position: absolute;
            bottom: 0;
            left: 0;
            height: auto;
            width: 100%;
            opacity: 0;
            animation: fadeIn 0.5s forwards;
            pointer-events: none;
          }

          a.characterName {
            top: calc(100% - 2rem);
            left: 50%;
            transform: translateX(-50%);
          }
        }

        // specific positions whether there are three or four characters in a party
        &.length-3 {
          .character {
            width: 60%;

            &.character-1 {
              left: -2rem;
              z-index: 2;

              img.illustration {
                animation-delay: 0.5s;
              }
            }

            &.character-2 {
              bottom: 15%;
              left: 50%;
              transform: translateX(-50%);
              z-index: 3;

              a.characterName {
                top: calc(100% - 5rem);
              }

              img.illustration {
                animation-delay: 0.25s;
              }
            }

            &.character-3 {
              right: -2rem;
              z-index: 0;

              img.illustration {
                animation-delay: 0.75s;
              }
            }
          }
        }

        &.length-4 {
          .character {
            width: 55%;

            &.character-1 {
              left: -15%;
              bottom: 45%;
              z-index: 2;

              img.illustration {
                animation-delay: 1s;
              }
            }

            &.character-2 {
              left: 50%;
              transform: translateX(-80%);
              bottom: 35%;
              z-index: 3;

              img.illustration {
                animation-delay: 0.5s;
              }
            }

            &.character-3 {
              left: 50%;
              transform: translateX(-20%);
              bottom: 35%;
              z-index: 3;

              img.illustration {
                animation-delay: 0.25s;
              }
            }

            &.character-4 {
              right: -15%;
              bottom: 45%;
              z-index: 2;

              img.illustration {
                animation-delay: 0.75s;
              }
            }
          }
        }

        &.camp5,
        &.camp1 {
          .character {
            &.character-2 {
              img.illustration {
                transform: translateY(-30%);
              }
            }
          }
        }

        &.camp4 {
          .character {
            &.character-2 {
              img.illustration {
                transform: translateY(-5%);
              }
            }
          }
        }

        &.camp5 {
          .dungeonMaster {
            z-index: 3;
          }

          .character {
            &.character-3 {
              img.illustration {
                transform: translateX(-30%);
                width: 120%;
              }
            }
          }
        }
      }
    }

    .background {
      position: relative;
      z-index: 1;
      width: calc(100% - 2rem);
      max-width: 35rem;
      margin: 3rem auto 4rem auto;
      opacity: 0.25;

      img {
        display: block;
        margin: 0 auto;
        max-width: calc(100% - 2rem);
      }
    }
  }
}

// handle transition between groupnames
.groupNameSlider {
  --farNextPos: -50%;
  --nextPos: 0%;
  --prevPos: 100%;
  --farPrevPos: 150%;
  --prevNextGroupNameOpacity: 0;
}

@for $i from 1 through list.length(mixins.$camps) {
  $farPrev: "group-camp" +
    if($i - 2 < 1, list.length(mixins.$camps) + ($i - 2), $i - 2);
  $prev: "group-camp" +
    if($i - 1 < 1, list.length(mixins.$camps) + ($i - 1), $i - 1);
  $active: "group-camp" + $i;
  $next: "group-camp" +
    if(
      $i + 1 > list.length(mixins.$camps),
      ($i + 1) - list.length(mixins.$camps),
      $i + 1
    );
  $farNext: "group-camp" +
    if(
      $i + 2 > list.length(mixins.$camps),
      ($i + 2) - list.length(mixins.$camps),
      $i + 2
    );

  #cast.camp#{$i} {
    #campaignControls .groupNameSlider .campaignDetails {
      &.#{$farNext} {
        left: var(--farNextPos);
        opacity: 0;
        transform: translate(-50%, 50%) scale(0.25);
        pointer-events: none;
      }

      &.#{$next} {
        left: var(--nextPos);
        opacity: var(--prevNextGroupNameOpacity);
        transform: translate(-50%, 0%) scale(0.5);
      }

      &.#{$active} {
        left: 50%;
        opacity: 1;
        z-index: 22;
        pointer-events: all;
      }

      &.#{$prev} {
        left: var(--prevPos);
        opacity: var(--prevNextGroupNameOpacity);
        transform: translate(-50%, 0%) scale(0.5);
      }

      &.#{$farPrev} {
        left: var(--farPrevPos);
        opacity: 0;
        transform: translate(-50%, 50%) scale(0.25);
        pointer-events: none;
      }
    }

    #campaigns {
      .groups {
        .group.camp#{$i} {
          display: block;
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (min-width: mixins.$breakpoint-md) {
  .groupNameSlider {
    --farNextPos: 0;
    --nextPos: 25%;
    --prevPos: 75%;
    --farPrevPos: 100%;
    --prevNextGroupNameOpacity: 0;
  }

  #cast {
    display: flex;

    #details {
      margin: 0;
      flex: 0 0 50%;
      align-content: center;
    }

    #campaigns {
      flex: 0 0 50%;
    }

    #campaignControls {
      .groupNameSlider {
        height: 11rem;
        scale: 1.1;

        .campaignDetails {
          h2 {
            font-size: var(--fs0);
          }
        }
      }
    }
    #campaigns {
      .groups {
        .group {
          --headshot-width: 7rem;
        }
      }
    }
  }
}

@media only screen and (min-width: mixins.$breakpoint-lg) {
  .groupNameSlider {
    --farNextPos: 0;
    --nextPos: 25%;
    --prevPos: 75%;
    --farPrevPos: 100%;
    --prevNextGroupNameOpacity: 0;
  }

  #cast {
    display: flex;
    justify-content: center;

    #details {
      flex: 0 0 50%;
      max-width: 30rem;
      align-content: center;
    }

    #campaigns {
      flex: 0 0 50%;
      max-width: 40rem;
    }
  }
}

// PATREON Section

#patreon_home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  // padding: 5rem 0;
  @include mixins.blackBackground;

  > * {
    position: relative;
    z-index: 1;
  }

  // .tiers-rewards {
  //   display: flex;
  // }

  ul.rewards,
  ul.tiers {
    padding: 0;
    width: 100%;
    margin: 5rem auto 0 auto;
    //max-width: 70rem;
  }

  ul.tiers {
    margin: 2rem auto;
    width: 100%;
    position: relative;
    height: 15rem;

    li.tier {
      padding: 0.5rem;
      box-sizing: border-box;
      list-style-type: none;
      color: var(--light-900);
      width: 50%;
      height: auto;
      font-size: var(--fs8);

      text-transform: uppercase;
      font-family: "Poppins", sans-serif;
      font-weight: 900;
      cursor: pointer;
      transition: all 0.25s ease;
      opacity: 0.5;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: none;

      &::after {
        content: "";
        display: block;
        position: absolute;
        @include mixin.triangle(bottom, var(--accent-500), 1rem);
        position: absolute;
        bottom: -2rem;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        transition: opacity 0.25s ease;
      }

      img {
        width: 100%;
        margin: 0 auto;
        max-width: 12rem;
        transform: scale(0.75);
        transition: transform 0.25s ease;
      }

      strong {
        font-family: var(--AccentFont);
        color: var(--accent-500);
        display: block;
        font-size: var(--fs11);

        font-weight: 700;
        margin-bottom: 0.5rem;
      }
    }
  }

  ul.rewards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 35rem;

    li.reward {
      flex: 0 0 33%;
      font-size: var(--fs12);

      margin: 0.5rem 0;
      list-style-type: none;
      color: var(--light-900);
      transition: all 0.25s ease;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      padding-top: 5rem;
      opacity: 0.1;

      img {
        width: 75%;
        max-width: 10rem;
        height: auto;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -2;
      }

      strong {
        color: var(--accent-500);
        display: block;
        font-size: var(--fs8);

        font-family: var(--AccentFont);
        font-weight: 900;
        text-transform: uppercase;
        margin: 0 auto;
        max-width: 10rem;
        text-shadow: var(--text-shadow);
      }
    }
  }

  .patreonLink {
    margin-top: 5rem;
    font-size: var(--fs9);

    line-height: var(--f5-line-height);
  }

  $tiers: 2, 5, 10, 25, 50;

  @for $i from 1 through list.length($tiers) {
    $tier: list.nth($tiers, $i);
    // we need to make it so that each subsequent tier makes
    // the previous tiers opacity 1 as well
    $rewardClasses: "";

    @for $ii from 1 through $i {
      @if $ii > 1 {
        $rewardClasses: $rewardClasses + ", ";
      }

      $rewardClasses: $rewardClasses + "li.reward" + list.nth($tiers, $ii);
    }

    &.tier#{list.nth($tiers, $i)} {
      ul.rewards {
        #{$rewardClasses} {
          opacity: 1 !important;
        }
      }
    }
  }

  $tierIndexes: list.length($tiers) * 2;

  @for $n from 1 through $tierIndexes {
    // create the positions from far left to far right
    $farOffscreenLeft: if($n - 4 < 1, $tierIndexes + ($n - 4), ($n - 4));
    $offscreenLeft: if($n - 3 < 1, $tierIndexes + ($n - 3), ($n - 3));
    $farLeft: if($n - 2 < 1, $tierIndexes + ($n - 2), ($n - 2));
    $left: if($n - 1 < 1, $tierIndexes + ($n - 1), ($n - 1));
    $active: $n;
    $right: if($n + 1 > $tierIndexes, ($n + 1) - $tierIndexes, $n + 1);
    $farRight: if($n + 2 > $tierIndexes, ($n + 2) - $tierIndexes, $n + 2);
    $offscreenRight: if($n + 3 > $tierIndexes, ($n + 3) - $tierIndexes, $n + 3);
    $farOffscreenRight: if(
      $n + 4 > $tierIndexes,
      ($n + 4) - $tierIndexes,
      $n + 4
    );

    // for the classes
    &.nth-#{$n} {
      .tier {
        &[data-n="#{$farOffscreenLeft}"] {
          display: block;
          transform: translateX(-450%);
        }

        &[data-n="#{$offscreenLeft}"] {
          display: block;
          transform: translateX(-350%);
        }

        &[data-n="#{$farLeft}"] {
          display: block;
          transform: translateX(-250%);
        }

        &[data-n="#{$left}"] {
          display: block;
          transform: translateX(-150%);
        }

        &[data-n="#{$active}"] {
          display: block;
          transform: translateX(-50%);
          opacity: 1 !important;

          img {
            transform: scale(1);
          }

          &::after {
            opacity: 1;
          }
        }

        &[data-n="#{$right}"] {
          display: block;
          transform: translateX(50%);
        }

        &[data-n="#{$farRight}"] {
          display: block;
          transform: translateX(150%);
        }

        &[data-n="#{$offscreenRight}"] {
          display: block;
          transform: translateX(250%);
        }

        &[data-n="#{$farOffscreenRight}"] {
          display: block;
          transform: translateX(350%);
        }
      }
    }
  }

  // inner page design

  .tiers {
    display: block;
    margin: 0 auto;
    max-width: 1400px;

    h2 {
      color: var(--light-800);

      span {
        color: var(--accent-500);
      }
    }

    h3 {
      color: var(--accent-500);
      font-family: var(--AccentFont);
      font-weight: 900;
      text-transform: uppercase;
      font-size: var(--fs8);
    }

    .tier {
      display: block;

      .badge {
        img {
          display: block;
          margin: 0 auto;
          width: 12rem;
          height: 12rem;
        }

        .join {
          display: flex;
          justify-content: center;
        }
      }

      .rewards {
        display: block;
        margin-top: 5rem;

        .reward {
          padding: 1rem;
          box-sizing: border-box;

          img {
            display: block;
            margin: 0 auto;
            width: 12rem;
            height: 12rem;
          }
        }
      }

      .plus {
        font-size: var(--fs8);

        letter-spacing: 4px;
        color: var(--accent-500);
        text-align: center;
        padding: 1rem 0;
        text-transform: uppercase;
        font-family: var(--AccentFont);
        max-width: 90%;
        margin: 0 auto;
      }
    }
  }
}

@media only screen and (min-width: mixins.$breakpoint-md) {
  #patreon_home {
    .tiers-rewards {
      display: block;
    }

    ul.tiers {
      height: 20rem;

      li.tier {
        width: 25%;
        margin-bottom: 3rem;

        &:hover {
          opacity: 0.65;
        }
      }
    }

    ul.rewards {
      display: flex;
      flex-wrap: wrap;
      max-width: 70rem;

      li.reward {
        flex: 0 0 25%;
        padding-top: 6rem;

        strong {
          font-size: var(--fs10);
        }

        img {
          top: 0;
        }

        &::before {
          height: 12rem;
          bottom: -5rem;
        }
      }
    }
  }
}

@media only screen and (min-width: mixins.$breakpoint-lg) {
  #patreon_home {
    ul.rewards {
      li.reward {
        flex: 0 0 16.66%;
      }
    }
  }
}

// PRINT SHOP

#printshop {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1,
  p {
    text-align: center;
  }

  .randomFanart {
    width: 100%;
    height: 15rem;
    margin: 0 auto;
    position: relative;

    .fanart {
      display: block;
      margin: 1rem auto;
      width: 7.5rem;
      height: 7.5rem;
      background: var(--light-low);
      position: absolute;
      border: 1px solid var(--border-color);
      box-shadow: var(--box-shadow);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.5s ease;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.spread {
      .fanart {
        &.fanart1 {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 5;

          &:hover {
            transform: translate(-50%, -70%);
            z-index: 6;
          }
        }

        &.fanart2 {
          top: 55%;
          left: 30%;
          transform: translate(-50%, -50%) rotate(-5deg);
          z-index: 4;

          &:hover {
            transform: translate(-50%, -70%) rotate(-5deg);
            z-index: 6;
          }
        }

        &.fanart3 {
          top: 55%;
          left: 70%;
          transform: translate(-50%, -50%) rotate(5deg);
          z-index: 3;

          &:hover {
            transform: translate(-50%, -70%) rotate(5deg);
            z-index: 6;
          }
        }

        &.fanart4 {
          top: 65%;
          left: 15%;
          transform: translate(-50%, -50%) rotate(-15deg);
          z-index: 2;

          &:hover {
            transform: translate(-50%, -70%) rotate(-15deg);
            z-index: 6;
          }
        }

        &.fanart5 {
          top: 65%;
          left: 85%;
          transform: translate(-50%, -50%) rotate(15deg);
          z-index: 1;

          &:hover {
            transform: translate(-50%, -70%) rotate(15deg);
            z-index: 6;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: mixin.$breakpoint-md) {
  #printshop {
    .randomFanart {
      height: 20rem;

      .fanart {
        width: 10rem;
        height: 10rem;
      }
    }
  }
}

@media only screen and (min-width: mixin.$breakpoint-lg) {
  #printshop {
    .randomFanart {
      width: 50rem;
      height: 25rem;

      .fanart {
        width: 15rem;
        height: 15rem;
      }
    }
  }
}
